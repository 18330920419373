import { useCallback, useMemo } from 'react'
import useNextAuthSession from '@lib/hooks/useSession'
import { NextRouter, useRouter as useNextRouter } from 'next/router'
import { constructUrlWithOrgId } from './utils'

export const useRouter = () => {
  const nextRouter = useNextRouter()
  const { data: session } = useNextAuthSession()
  const orgId = session?.orgId

  const push: NextRouter['push'] = useCallback(
    (url, as, options) => {
      const urlWithOrgId = constructUrlWithOrgId({ orgId, url })

      return nextRouter.push(urlWithOrgId, as, options)
    },
    [nextRouter, orgId],
  )

  const replace: NextRouter['replace'] = useCallback(
    (url, as, options) => {
      const urlWithOrgId = constructUrlWithOrgId({ orgId, url })

      return nextRouter.replace(urlWithOrgId, as, options)
    },
    [nextRouter, orgId],
  )

  const router = useMemo(
    () => ({
      ...nextRouter,
      pushRoot: nextRouter.push,
      replaceRoot: nextRouter.replace,
      push,
      replace,
    }),
    [nextRouter, push, replace],
  )

  return router
}
