import classNames from 'classnames'
import { ReactNode } from 'react'

interface IContributorContainer {
  title?: string
  contributors: ReactNode
  containerClassName?: string
}

const ContributorContainer = ({
  contributors,
  title,
  containerClassName,
}: IContributorContainer) => {
  return (
    <>
      <div
        className={classNames([
          'relative mx-[-16px] rounded-b-md border-b border-oxford-gray-200',
          containerClassName,
        ])}
      >
        <div className="px-3 py-3">
          {title && (
            <div className="text-oxford-gray-400 uppercase mb-2 text-xs">
              {title}
            </div>
          )}
          <div className="flex flex-col gap-3">{contributors}</div>
        </div>
      </div>
    </>
  )
}

export default ContributorContainer
