import { CustomAuthorizator } from '../Authorizator/Authorizator'
import { PERMISSIONS } from '@utils/constants/permissions'

export const createAdminPanelAuthorizator = () => {
  function createManageAuthorizator(permission: string | undefined) {
    return ({ userPermissions }) => {
      if (!permission) return false

      return userPermissions.includes(permission)
    }
  }

  const adminPanelAccess: CustomAuthorizator = ({ userPermissions }) =>
    !!userPermissions.find((p) =>
      p.includes(PERMISSIONS.FE_NAVIGATION_ADMIN_PREFIX),
    )

  return {
    adminPanelAccess,
    createManageAuthorizator,
  }
}
