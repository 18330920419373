import React, { useState } from 'react'
import { Menu, MenuItem, MenuItems, Transition } from '@headlessui/react'
import classNames from 'classnames'
import Link from '@components/Router/Link'
import type { Url } from 'next/dist/shared/lib/router/router'
import { usePopper } from 'react-popper'
import type { Placement, PositioningStrategy } from '@popperjs/core'
import { defaultModifiers } from '@utils/popper'

export interface IMenuItem {
  id: number | string
  href?: Url
  label?: any
  disabled?: boolean
  componentProps?: Record<string, any>
  onClick?: any
  renderContent?: Function
}

const DropdownMenu = ({
  items,
  label,
  renderLabel,
  popperPlacement,
  popperStrategy,
  className,
}: {
  items: IMenuItem[]
  label?: string
  className?: string
  popperPlacement?: Placement
  popperStrategy?: PositioningStrategy
  renderLabel?: Function
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement>()
  const [popperElement, setPopperElement] = useState<HTMLDivElement>()
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: popperPlacement,
    strategy: popperStrategy,
    modifiers: defaultModifiers,
  })

  return (
    <div>
      <Menu as="div" className="relative flex-shrink-0">
        <div ref={setReferenceElement}>
          <Menu.Button
            className={classNames([
              'flex focus:outline-none text-oxford-gray-400 active:text-white',
            ])}
          >
            {renderLabel?.(label) ?? (
              <span className="flex content-center items-center">{label}</span>
            )}
          </Menu.Button>
        </div>
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="z-20"
        >
          <Transition
            as="div"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              className={classNames([
                'absolute right-0 mt-2 origin-top-right rounded-md bg-white shadow-lg overflow-hidden focus:outline-none',
                className,
              ])}
            >
              {items.map((item, index) => {
                if (!item) return null

                const isButton = !!item.onClick
                const Component = isButton ? 'button' : Link

                return (
                  <MenuItem key={item.id}>
                    {({ focus }) => (
                      <Component
                        disabled={item.disabled}
                        href={!isButton ? item.href : undefined}
                        className={classNames([
                          'block px-4 py-3 text-sm w-100 text-left truncate leading-none',
                          {
                            'text-oxford-gray-800': !item.disabled,
                            'text-oxford-gray-300': item.disabled,
                            'text-primary': focus,
                            'border-t border-t-oxford-gray-100': index !== 0,
                            'hover:bg-electric-violet-50 hover:text-primary':
                              !item.disabled,
                          },
                        ])}
                        prefetch={!isButton ? false : undefined}
                        onClick={isButton ? item.onClick : undefined}
                        {...item.componentProps}
                      >
                        {item.label}
                      </Component>
                    )}
                  </MenuItem>
                )
              })}
            </MenuItems>
          </Transition>
        </div>
      </Menu>
    </div>
  )
}

export default DropdownMenu
