import { UUID } from '@lib/types/general'
import { UserAccess } from '@lib/graphql/__generated__/graphql'
import { CONTRIBUTOR_RIGHTS, CONTRIBUTOR_TYPE } from '@utils/constants'
import { teamToContributor } from '@utils/contributors'
import { useFormikContext } from 'formik'
import { User } from '@lib/types/user'

const useContributorActions = (type: string, resourceOwner: User) => {
  const { values, setFieldValue }: any = useFormikContext()

  const { userContributors, teamContributors }: any = values

  const viewPremission = CONTRIBUTOR_RIGHTS().VIEWER.value

  const handleAddUser = (
    user: User,
    access: UserAccess = viewPremission as UserAccess,
  ) => {
    setFieldValue('userContributors', [
      ...userContributors,
      { account: user, access },
    ])
  }

  const handleAddTeam = (_, { dataItem }) => {
    const teamContributor = teamToContributor(dataItem, resourceOwner)

    setFieldValue('teamContributors', [
      ...values.teamContributors,
      teamContributor,
    ])
  }

  const handleUserPermissionChange = (props) => {
    if (type === CONTRIBUTOR_TYPE.USER) {
      const editedContributors = userContributors.map((contributor) =>
        contributor.account.id === props.userId
          ? { ...contributor, access: props.value }
          : contributor,
      )

      return setFieldValue('userContributors', editedContributors)
    }

    if (type === CONTRIBUTOR_TYPE.TEAM) {
      const updatedTeams = teamContributors.map(({ team, contributors }) => {
        if (team.id === props.teamId) {
          return {
            team,
            contributors: contributors.map((user) =>
              user.account.id === props.userId
                ? { ...user, access: props.value }
                : user,
            ),
          }
        }
        return { team, contributors }
      })

      return setFieldValue('teamContributors', updatedTeams)
    }
  }

  const handleUserDelete = (props) => {
    if (type === CONTRIBUTOR_TYPE.USER) {
      const filteredContributors = userContributors.filter(
        ({ account }) => account.id !== props.userId,
      )

      return setFieldValue('userContributors', filteredContributors)
    }

    if (type === CONTRIBUTOR_TYPE.TEAM) {
      const updatedTeams = teamContributors.map((teamContributor) =>
        teamContributor.team.id === props.teamId
          ? {
              ...teamContributor,
              contributors: teamContributor.contributors.filter(
                ({ account }) => account.id !== props.userId,
              ),
            }
          : teamContributor,
      )

      return setFieldValue('teamContributors', updatedTeams)
    }
  }

  const handleTeamDelete = (teamId) => {
    const filteredTeams = teamContributors.filter(
      ({ team }) => team.id !== teamId,
    )

    setFieldValue('teamContributors', filteredTeams)
  }

  const handleTeamMembersAccessChange = (teamId: UUID, access: UserAccess) => {
    const updatedTeams = teamContributors.map((teamContributor) => {
      if (teamContributor.team.id === teamId) {
        return {
          ...teamContributor,
          contributors: teamContributor.contributors.map((user) => ({
            ...user,
            access: access,
          })),
        }
      }
      return teamContributor
    })

    setFieldValue('teamContributors', updatedTeams)
  }

  return {
    onContributorAdd:
      type === CONTRIBUTOR_TYPE.USER ? handleAddUser : handleAddTeam,
    onContributorDelete: handleUserDelete,
    onPermissionChange: handleUserPermissionChange,
    onTeamDelete: handleTeamDelete,
    onTeamMembersAccessChange: handleTeamMembersAccessChange,
  }
}

export default useContributorActions
