import { Feature } from '@lib/graphql/__generated__/graphql'
import { isToDo } from '@utils/Task'

export const getReminderType = (reminderRow: any) => {
  if (reminderRow) {
    switch (true) {
      case isToDo(reminderRow.task):
        return REMINDER_TYPE.toDo
      case !!reminderRow.goal:
        return REMINDER_TYPE.goal
      case !!reminderRow.milestone:
        return REMINDER_TYPE.milestone
      case !!reminderRow.task:
        return REMINDER_TYPE.task
      case !!reminderRow.projectMaterial:
        return REMINDER_TYPE.projectMaterial
      case !!reminderRow.order:
        return REMINDER_TYPE.order
      case !!reminderRow.productionPlanningProject:
        return REMINDER_TYPE.productionPlanning
      case !!reminderRow.keyMilestonesProject:
        return REMINDER_TYPE.keyActivity
      case !!reminderRow.keyActivity:
        return REMINDER_TYPE.keyActivity
      case !!reminderRow.comment:
        return REMINDER_TYPE.comment
      case !!reminderRow.thread:
        return REMINDER_TYPE.thread
      default:
        return null
    }
  }
}

export enum REMINDER_TYPE {
  goal = 'goal',
  milestone = 'milestone',
  task = 'task',
  productionPlanning = 'productionPlanning',
  keyActivity = 'keyActivity',
  comment = 'comment',
  toDo = 'toDo',
  order = 'order',
  projectMaterial = 'projectMaterial',
  thread = 'thread',
}

export const REMINDER_TYPE_FEATURES = {
  [REMINDER_TYPE.order]: Feature.Bom,
  [REMINDER_TYPE.projectMaterial]: Feature.Bom,
  [REMINDER_TYPE.thread]: Feature.Chat,
} as const
