import Popover from '@components/Shared/Popover/Popover'
import classNames from 'classnames'
import AssignAnotherOwner from './AssignAnotherOwner'
import { User } from '@lib/types/user'

interface IOwnerMoreActions {
  owner: User
  onOwnerChange: Function
  disabled?: boolean
}

const OwnerMoreActions = ({
  disabled = false,
  onOwnerChange,
  ...rest
}: IOwnerMoreActions) => {
  return onOwnerChange ? (
    <Popover
      popperPlacement="bottom-end"
      disabled={disabled}
      button={
        <i
          className={classNames([
            'far fa-ellipsis text-2xl px-3',
            {
              'text-primary': !disabled,
              'text-oxford-gray-300': disabled,
            },
          ])}
        />
      }
      content={({ close }) => (
        <AssignAnotherOwner
          onOwnerChange={onOwnerChange}
          onClose={close}
          {...rest}
        />
      )}
    />
  ) : null
}

export default OwnerMoreActions
