import React, { useTransition, useState } from 'react'
import isEmpty from 'ramda/src/isEmpty'
import toLower from 'ramda/src/toLower'
import { ListboxOptions } from '@headlessui/react'
import SearchBox from '../../SearchBox'
import MultiselectOption from './MultiselectOption'
import classNames from 'classnames'
import type { IMultiselectProps } from '../Multiselect'
import type { Placement } from '@popperjs/core'
import DropdownListNoDataOption from '@components/Ui/Dropdown/List/DropdownListNoDataOption'
import DropdownListCreateOption from '@components/Ui/Dropdown/List/DropdownListCreateOption'

export interface IMultiselectListProps extends Partial<IMultiselectProps> {
  popperStyles?: { [key: string]: React.CSSProperties }
  popperAttributes?: { [key: string]: { [key: string]: string } | undefined }
  popperPlacement?: Placement | null
  setPopperElement?: React.Ref<HTMLDivElement>
}

const initialFilterState = ''

const MultiselectList = ({
  data,
  loading,
  valueField,
  textField,
  value,
  renderItem,
  allowCreate,
  isItemRemovable,
  popperPlacement,
  setPopperElement,
  popperStyles,
  popperAttributes,
}: IMultiselectListProps) => {
  const [_, startTransition] = useTransition()
  const [filter, setFilter] = useState(initialFilterState)

  if (loading && (!data || isEmpty(data))) {
    return null
  }

  if (filter) {
    data = data.filter((dataItem) =>
      toLower(dataItem[textField]).includes(toLower(filter.trim())),
    )
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      setFilter('')
    }
  }

  return (
    <ListboxOptions
      transition
      ref={setPopperElement}
      onKeyDown={onKeyDown}
      style={popperStyles.popper}
      {...popperAttributes.popper}
      className={classNames([
        'absolute z-20 flex flex-col py-1 w-full max-h-[250px] bg-white border border-oxford-gray-100 shadow-lg origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0',
        {
          'rounded-b-md': popperPlacement && popperPlacement !== 'top',
          'rounded-t-md': popperPlacement && popperPlacement === 'top',
        },
      ])}
    >
      <React.Fragment>
        <SearchBox
          value={filter}
          onChange={(filter) => startTransition(() => setFilter(filter))}
        />
        <div className="flex flex-col overflow-auto">
          {data && !isEmpty(data) ? (
            data.map((dataItem) => {
              if (
                typeof dataItem?.isActive === 'boolean' &&
                dataItem?.isActive === false
              ) {
                return null
              }

              return (
                <MultiselectOption
                  key={dataItem[valueField]}
                  dataItem={dataItem}
                  textField={textField}
                  valueField={valueField}
                  value={value}
                  renderItem={renderItem}
                  disabled={!isItemRemovable?.({ item: dataItem, data })}
                  onClick={() => {
                    setFilter(initialFilterState)
                  }}
                />
              )
            })
          ) : (
            <DropdownListNoDataOption />
          )}

          {allowCreate &&
            !!filter.trim().length &&
            !data.find(
              (dataItem) =>
                toLower(dataItem[textField]) === toLower(filter.trim()),
            ) && (
              <DropdownListCreateOption
                filter={filter}
                textField={textField}
                setFilter={setFilter}
              />
            )}
        </div>
      </React.Fragment>
    </ListboxOptions>
  )
}

export default MultiselectList
