import { gql } from '../__generated__'

export const GET_COUNTERS = gql(`
  query GetCounters {
    getCounters {
      ...CountersFragment
    }
  }
`)

export const GET_MY_TASKS_COUNTERS = gql(`
  query GetMyTasksCounters {
    getCounters {
      ...MyTasksCountersFragment
    }
  }
`)

export const GET_TODOS_COUNTERS = gql(`
  query GetToDosCounters {
    getCounters {
      ...ToDosCountersFragment
    }
  }
`)
