import { UUID } from '@lib/types/general'

export const createBoardFiltersStorageKey = (boardId: UUID) =>
  `board.${boardId}.state`

export const createCollectionFiltersStorageKey = (collectionId: UUID) =>
  `collection.${collectionId}.state`

export const createGridScrollPositionStorageKey = (entity: string) => {
  if (!entity) return null

  return `${entity}.scroll-position`
}

export const getStoredState = (key: string) => {
  try {
    return JSON.parse(sessionStorage.getItem(key))
  } catch (e) {
    return {}
  }
}

export const storeState = (key: string, state: Record<string, any>) =>
  sessionStorage.setItem(key, JSON.stringify(state))

export const clearState = (key?: string) =>
  key ? sessionStorage.removeItem(key) : sessionStorage.clear()
