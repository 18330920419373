import React from 'react'
import classnames from 'classnames'
import Spinner from '@components/Shared/Loaders/Spinner'

export enum BUTTON_TYPE {
  SOLID = 'solid',
  OUTLINE = 'outline',
  TEXT = 'text',
}

export enum BUTTON_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum BUTTON_COLOR {
  PRIMARY = 'primary',
  DANGER = 'danger',
  SUCCESS = 'success',
}

export interface IButtonProps {
  children: React.ReactNode | undefined
  onClick: any
  type?: BUTTON_TYPE
  size?: BUTTON_SIZE
  color?: BUTTON_COLOR
  colorClass?: string
  className?: string
  disabled?: boolean
  loading?: boolean
  leftIcon?: string
  rightIcon?: string
}

const theme = {
  shared:
    'inline-flex items-center content-center justify-center rounded-md whitespace-nowrap focus:outline-none disabled:hover:shadow-none disabled:cursor-not-allowed',
  type: {
    [BUTTON_TYPE.SOLID]: 'shadow-sm border disabled:opacity-90',
    [BUTTON_TYPE.OUTLINE]: 'shadow-sm border disabled:opacity-90',
    [BUTTON_TYPE.TEXT]: '',
  },
  size: {
    [BUTTON_SIZE.SMALL]: 'py-2 px-3 text-xs',
    [BUTTON_SIZE.MEDIUM]: 'h-7 py-2 px-5 text-sm',
    [BUTTON_SIZE.LARGE]: 'py-3 px-6 text-xl',
  },
  color: {
    [BUTTON_COLOR.PRIMARY]: {
      [BUTTON_TYPE.SOLID]:
        'bg-primary text-white font-semibold border-primary hover:bg-electric-violet-500 hover:border-electric-violet-500 hover:shadow-electric-violet-400 active:bg-electric-violet-800 disabled:bg-electric-violet-200 disabled:border-electric-violet-200 disabled:hover:bg-electric-violet-200 disabled:active:bg-electric-violet-200',
      [BUTTON_TYPE.OUTLINE]:
        'bg-white text-primary border-primary hover:bg-electric-violet-50 hover:shadow-electric-violet-400 active:bg-electric-violet-100 disabled:border-electric-violet-400 disabled:text-electric-violet-400 disabled:hover:bg-white disabled:active:bg-white',
      [BUTTON_TYPE.TEXT]:
        'text-primary hover:text-electric-violet-500 active:text-electric-violet-600 disabled:text-electric-violet-300 disabled:hover:bg-white disabled:active:bg-white',
    },
    [BUTTON_COLOR.DANGER]: {
      [BUTTON_TYPE.SOLID]:
        'bg-watermelon-800 text-white border-watermelon-800 hover:bg-watermelon-500 hover:border-watermelon-500 hover:shadow-sm active:bg-watermelon-900 disabled:bg-watermelon-200 disabled:border-watermelon-200',
      [BUTTON_TYPE.OUTLINE]:
        'bg-white text-watermelon-800 border-watermelon-800 hover:bg-watermelon-50 hover:shadow-sm active:bg-watermelon-100 disabled:border-watermelon-300 disabled:text-watermelon-300',
      [BUTTON_TYPE.TEXT]:
        'text-watermelon-800 hover:text-watermelon-500 active:text-watermelon-600 disabled:text-watermelon-300',
    },
    [BUTTON_COLOR.SUCCESS]: {
      [BUTTON_TYPE.SOLID]:
        'bg-jade-500 text-white border-jade-500 hover:bg-jade-300 hover:border-jade-50 hover:shadow-sm active:bg-jade-300 disabled:bg-jade-200 disabled:border-jade-200',
      [BUTTON_TYPE.OUTLINE]:
        'bg-white text-jade-500 border-jade-500 hover:bg-jade-300 hover:shadow-sm active:bg-jade-300 disabled:border-jade-300 disabled:text-jade-300',
      [BUTTON_TYPE.TEXT]:
        'text-jade-500 hover:text-jade-300 active:text-jade-300 disabled:text-jade-300',
    },
  },
}

const Button: React.FunctionComponent<IButtonProps> = ({
  className,
  disabled,
  loading,
  children,
  leftIcon,
  rightIcon,
  colorClass,
  onClick,
  type = BUTTON_TYPE.SOLID,
  size = BUTTON_SIZE.MEDIUM,
  color = BUTTON_COLOR.PRIMARY,
}) => {
  const renderContent = () => {
    if (leftIcon) {
      return (
        <>
          <i className={`${leftIcon} mr-2 pointer-events-none`}></i>
          {children}
        </>
      )
    }

    if (rightIcon) {
      return (
        <>
          {children}
          <i className={`${rightIcon} ml-2 pointer-events-none`}></i>
        </>
      )
    }

    return children
  }

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={loading || disabled}
      className={classnames([
        theme.shared,
        theme.type[type],
        theme.size[size],
        colorClass ? colorClass : theme.color[color][type],
        className,
      ])}
    >
      {loading ? (
        <Spinner className={type === BUTTON_TYPE.SOLID ? 'text-white' : ''} />
      ) : (
        renderContent()
      )}
    </button>
  )
}

export default Button
