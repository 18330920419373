import React, { useEffect, useMemo, Suspense, useRef } from 'react'
import { ApolloProvider, useLazyQuery } from '@apollo/client'
import { SessionProvider, useSession } from 'next-auth/react'
import { useApollo } from '@lib/graphql/apollo'
import Toast from '@components/Toast'
import Noop from '@components/common/noop'
import { REFRESH_TOKEN_ERROR } from '@utils/constants/auth'
import TranslationsProvider from '@locales/TranslationsProvider'
import DialogProvider from '@components/Shared/Dialog/provider'
import useRouterSubscriptions from '@lib/hooks/useRouterSubscriptions'
import WithIntercom from '@components/Shared/HOC/WithIntercom'
import LayoutRoot from '@components/Shared/Layout/LayoutRoot'
import { Montserrat } from 'next/font/google'
import WithSharedData from '@components/Shared/HOC/WithSharedData'
import { SessionExtended } from '@lib/types/nextauth'
import { DEFAULT_LOCALE } from '@utils/i18n'
import { signOutClient } from '@utils/auth'
import WithBrowserNotifications from '@components/Shared/HOC/WithBrowserNotifications'
import useDateOptions from '@lib/hooks/useDateOptions'
import { GET_COUNTERS } from '@lib/graphql/queries/Shared'
import { COUNT_SUBSCRIPTION } from '@lib/graphql/subscriptions/Shared'
import { CounterType } from '@lib/graphql/__generated__/graphql'
import { unreadThreadsCountSubscriptionDataHandler } from '@lib/grid/subscriptions/subscriptionsEventHandlers'
import ErrorBoundary from '@components/Error/ErrorBoundary'
import Head from 'next/head'

const montserrat = Montserrat({
  variable: '--font-family-default',
  style: 'normal',
  subsets: ['cyrillic', 'latin'],
})

interface IAppMainProps {
  Component: any
  pageProps: Record<string, any>
}

const CustomHead = () => {
  const { status } = useSession()

  const isSubscribed = useRef(false)

  const [getCounters, { data: counters, subscribeToMore }] =
    useLazyQuery(GET_COUNTERS)

  useEffect(() => {
    if (status === 'unauthenticated') return

    getCounters()

    if (!isSubscribed.current) {
      subscribeToMore({
        document: COUNT_SUBSCRIPTION,
        variables: {
          counterType: CounterType.UserUnreadThreads,
        },
        updateQuery: unreadThreadsCountSubscriptionDataHandler,
      })

      isSubscribed.current = true
    }
  }, [getCounters, subscribeToMore, status])

  const notificationCount = counters?.getCounters?.unreadThreads ?? 0

  return (
    <Head>
      <link
        rel="icon"
        type="image/x-icon"
        href={
          notificationCount > 0
            ? '/favicon-red-dot.ico'
            : '/favicon-default.ico'
        }
      />
    </Head>
  )
}

const AppMain: React.FunctionComponent<IAppMainProps> = ({
  Component,
  pageProps,
}) => {
  const sessionData = useSession()

  const session = sessionData.data as SessionExtended

  //TODO: remove uppercase after a while 26.08.24
  const lang = session?.lang?.toUpperCase() ?? pageProps.lang ?? DEFAULT_LOCALE

  const apolloConfig = useMemo(
    () => ({
      session,
    }),
    [session],
  )
  const apolloClient = useApollo(pageProps.initialApolloState, apolloConfig)
  const Layout = (Component as any).Layout || Noop

  useEffect(() => {
    setTimeout(() => document.body.classList?.remove('loading'), 50)
  }, [])

  useRouterSubscriptions()
  useDateOptions({ lang })

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${montserrat.style.fontFamily};
          --font-family-default: ${montserrat.style.fontFamily};
        }
      `}</style>
      <Suspense fallback={null}>
        <ApolloProvider client={apolloClient}>
          <ErrorBoundary>
            <WithIntercom session={session}>
              <TranslationsProvider lang={lang}>
                <WithSharedData session={session}>
                  <WithBrowserNotifications>
                    <CustomHead />
                    <LayoutRoot>
                      <DialogProvider>
                        <Layout pageProps={pageProps}>
                          <Component {...pageProps} />
                        </Layout>
                      </DialogProvider>
                      <Toast />
                    </LayoutRoot>
                  </WithBrowserNotifications>
                </WithSharedData>
              </TranslationsProvider>
            </WithIntercom>
          </ErrorBoundary>
        </ApolloProvider>
      </Suspense>
    </>
  )
}

const SessionGuard = ({ children, skipSession }) => {
  const { data, status } = useSession()

  const shouldSignOut =
    !skipSession &&
    ((data as SessionExtended)?.error === REFRESH_TOKEN_ERROR ||
      status === 'unauthenticated' ||
      (data && !(data as SessionExtended)?.orgId))

  useEffect(() => {
    if (shouldSignOut) {
      signOutClient({
        callbackUrl: window.location.origin + '/auth/signed-out',
      })
    }
  }, [shouldSignOut])

  if (status === 'loading' && !data) return null

  if (shouldSignOut) return null

  return children
}

//eslint-disable-next-line
const withSession = (WrappedComponent) => (props) => {
  const { Component } = props

  const skipSession = Component?.auth?.skipSession ?? false

  return (
    <SessionProvider refetchInterval={120}>
      <SessionGuard skipSession={skipSession}>
        <WrappedComponent {...props} />
      </SessionGuard>
    </SessionProvider>
  )
}

export default withSession(AppMain)
