import React, { Fragment, useState, useMemo, useTransition } from 'react'
import { isEmpty, toLower, identity, filter as RFilter } from 'ramda'
import { ListboxOptions } from '@headlessui/react'
import classnames from 'classnames'
import SearchBox from '../../SearchBox'
import DropdownListOption from './DropdownListOption'
import DropdownListNoDataOption from './DropdownListNoDataOption'
import type { IDropdown } from '../Dropdown'
import type { Placement } from '@popperjs/core'
import DropdownListCreateOption from './DropdownListCreateOption'

interface IDropdownList extends IDropdown {
  popperStyles: { [key: string]: React.CSSProperties }
  popperAttributes: { [key: string]: { [key: string]: string } | undefined }
  popperPlacement: Placement | null
  setPopperElement: React.Ref<HTMLDivElement>
}

export const initialFilterState = ''

const DropdownList = ({
  value,
  data: propsData = [],
  dataParser = identity,
  textField = 'name',
  valueField = 'id',
  renderItem,
  allowCreate,
  allowSearch = true,
  loading,
  listHeight = 'max-h-[250px]',
  popperStyles,
  popperAttributes,
  popperPlacement,
  setPopperElement,
  renderListTop,
}: IDropdownList) => {
  const [_, startTransition] = useTransition()
  const [filter, setFilter] = useState(initialFilterState)

  const data = useMemo(() => {
    return RFilter((dataItem) => {
      if (filter) {
        return toLower(dataItem[textField]).includes(toLower(filter.trim()))
      }

      return dataItem
    })(dataParser(propsData || []))
  }, [propsData, dataParser, filter, textField])

  if (loading && (!propsData || isEmpty(propsData))) {
    return null
  }

  return (
    <ListboxOptions
      transition
      ref={setPopperElement}
      style={popperStyles.popper}
      {...popperAttributes.popper}
      className={classnames([
        'absolute z-20 flex flex-col py-1 w-full bg-white border border-oxford-gray-100 shadow-lg origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0',
        listHeight,
        {
          'rounded-b-md': popperPlacement && popperPlacement !== 'top',
          'rounded-t-md': popperPlacement && popperPlacement === 'top',
        },
      ])}
    >
      <Fragment>
        {allowSearch && (
          <SearchBox
            onChange={(filter) => startTransition(() => setFilter(filter))}
          />
        )}
        {renderListTop?.()}
        <div className="flex flex-col overflow-auto">
          {data && !isEmpty(data) ? (
            data.map((dataItem) => {
              if (
                typeof dataItem?.isActive === 'boolean' &&
                dataItem?.isActive === false
              ) {
                return null
              }

              return (
                <DropdownListOption
                  key={dataItem[valueField]}
                  dataItem={dataItem}
                  textField={textField}
                  valueField={valueField}
                  value={value}
                  renderItem={renderItem}
                />
              )
            })
          ) : (
            <DropdownListNoDataOption />
          )}

          {allowCreate &&
            !!filter.trim().length &&
            !data.find(
              (dataItem) =>
                toLower(dataItem[textField]) === toLower(filter.trim()),
            ) && (
              <DropdownListCreateOption
                filter={filter}
                textField={textField}
                setFilter={setFilter}
              />
            )}
        </div>
      </Fragment>
    </ListboxOptions>
  )
}

export default DropdownList
