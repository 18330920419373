export const GRID_ENTITY = {
  DASHBOARD_NOTIFICATIONS: 'Dashboard/Notifications',
  COMMENTS: 'Comments',
  DASHBOARD_TASKS: 'Dashboard/Tasks',
  DASHBOARD_TODOS: 'Dashboard/ToDos',
  DASHBOARD_REMINDERS: 'Dashboard/Reminders',
  DASHBOARD_GOALS: 'Dashboard/Goals',
  PROJECTS: 'Projects',
  SELECTABLE_PROJECTS: 'SelectableProjects',
  DYNAMIC_PLANNER: 'DynamicPlanner',
  MY_TASKS: 'MyTasks',
  MY_GOALS: 'MyGoals',
  GOALS: 'Goals',
  TODO: 'ToDo',
  SIZE_MATRICES: 'SizeMatrices',
  MATERIALS_DATABASE: 'MaterialsDatabase',
  ORDERS: 'Orders',
  PROJECT_MILESTONES: 'ProjectMilestones',
  PROJECT_MATERIALS: 'ProjectMaterials',
  BOM_LIST: 'BOMList',
  PRODUCTION_PLANNING: 'ProductionPlanning',
  PRODUCTION_PLANNING_ASSIGNED_PROVIDERS:
    'ProductionPlanning/AssignedProviders',
  PRODUCTION_PLANNING_PROVIDER_TRACKING: 'ProductionPlanning/ProviderTracking',
  MILESTONE_DEPENDENCY: 'MilestoneDependency',
  ATTACHMENTS: 'Attachments',
  RECURRING_TASKS: 'RecurringTasks',
  NOTIFICATIONS_LIST: 'NotificationsList',
  NOTIFICATIONS_DROPDOWN: 'NotificationDropdown',
  CRITICAL_PATH_DIALOG_MILESTONES: 'CriticalPathDialogMilestones',
  GOAL_PROJECTS: 'GoalProjects',
  GOAL_TASKS: 'GoalTasks',
  TIME_TRACKING_REPORT_LIST: 'TimeTrackingReportList',
  TIMELINE_REPORT_LIST: 'TimelineReportList',
  ALL_TASKS_REPORT_LIST: 'AllTasksReportList',
  USAGE_REPORT_LIST: 'UsageReportList',
  KEY_MILESTONES_LIST: 'KeyMilestonesList',
  WAREHOUSES: 'Warehouses',
  WAREHOUSE_INVENTORY: 'WarehouseInventory',
  MATERIAL_TYPES: 'MaterialTypes',
  FASHION_COLLECTION_ITEMS: 'FashionCollectionItems',
  ADDRESSES: 'Addresses',
  ADMIN_USERS: 'Administration/Users',
  ADMIN_INVITATIONS: 'Administration/Invitations',
  ADMIN_BASE_MILESTONES: 'Administration/BaseMilestones',
  ADMIN_TEAMS: 'Administration/Teams',
  ADMIN_CLIENTS: 'Administration/Clients',
  ADMIN_PRODUCERS: 'Administration/Producers',
  ADMIN_JOB_TITLES: 'Administration/JobTitles',
  ADMIN_PROJECT_TYPES: 'Administration/ProjectTypes',
  ADMIN_PROJECT_FIELDS: 'Administration/ProjectFields',
  ADMIN_TIME_FACTORS: 'Administration/TimeFactors',
  ADMIN_PROCEDURES: 'Administration/Procedures',
  ADMIN_SIZE_STANDARDS: 'Administration/SizeStandards',
  ADMIN_SIZE_STANDARD_CHILD_ROW: 'Administration/SizeStandardChildRow',
  ADMIN_SUPPLIERS: 'Administration/Suppliers',
  ADMIN_SUPPLIERS_CHILD_ROW: 'Administration/SuppliersChildRow',
  ADMIN_MANUFACTURERS: 'Administration/Manufacturers',
  ADMIN_CATEGORIES: 'Administration/Categories',
  ADMIN_LABELS: 'Administration/Labels',
  ADMIN_WORKING_DAYS_EXCEPTIONS: 'Administration/WorkingDaysExceptions',
  MANAGEMENT_ORGANIZATIONS: 'Management/Organizations',
  MILESTONE_DATES_HISTORY: 'MilestoneDatesHistory',
}
