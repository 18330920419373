import { Listbox } from '@headlessui/react'
import useTranslations from '@locales/useTranslations'

const DropdownListNoDataOption = () => {
  const { t } = useTranslations()

  return (
    <Listbox.Option
      disabled
      className="cursor-default select-none relative p-3"
      value={'No data'}
    >
      <span className="block truncate text-xs text-oxford-gray-800">
        {t('expressions.noDataAvailable')}
      </span>
    </Listbox.Option>
  )
}

export default DropdownListNoDataOption
