export const DATE_FORMAT = 'd MMM yyyy'

export const DATETIME_FORMAT = 'd MMM yyyy, HH:mm'

export const DATETIME_YEARLESS_FORMAT = 'd MMMM, HH:mm'

export const TIME_FORMAT = 'HH:mm'

export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD'

export const BACKEND_DATETIME_FORMAT = 'YYYY-MM-DD, HH:mm'
