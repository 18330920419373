import React from 'react'

export const Noop = ({ children }) => children()

const ControlDataLoader = ({ children, open, DataLoader, ...rest }) => {
  if (!open) return <Noop>{children}</Noop>

  return <DataLoader {...rest}>{children}</DataLoader>
}

export default ControlDataLoader
