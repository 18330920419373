import { useRouter } from '@components/Router/useRouter'
import { useEffect } from 'react'

const onEvent =
  (progressBarCallbackName: string) =>
  async (...args: any[]) => {
    const progressBar = (await import('@utils/progressBar/progressBar')).default

    progressBar[progressBarCallbackName](...args)
  }

const useRouterSubscriptions = () => {
  const router = useRouter()

  useEffect(() => {
    const onStart = onEvent('start')
    const onFinish = onEvent('finish')

    router.events.on('routeChangeStart', onStart)
    router.events.on('routeChangeComplete', onFinish)
    router.events.on('routeChangeError', onFinish)

    return () => {
      router.events.off('routeChangeStart', onStart)
      router.events.off('routeChangeComplete', onFinish)
      router.events.off('routeChangeError', onFinish)
    }
  }, [router.events])
}

export default useRouterSubscriptions
