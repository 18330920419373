import { REMOTE_DATA_GRID_REFRESH_CACHE } from '@utils/constants'
import EventEmitter from './EventEmitter'

export const refreshRemoteDataGridCache = (entity, purge?: boolean) => {
  if (!entity) console.warn('Entity is missing in refreshRemoteDataGridCache')

  EventEmitter.emit(REMOTE_DATA_GRID_REFRESH_CACHE, {
    entity,
    purge,
  })
}
