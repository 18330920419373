import { getLocale, setLocale } from './locale'

export interface IConfiguration {
  minReplaceRegexp: RegExp
  maxReplaceRegexp: RegExp
  locale?: Record<string, string>
}

let Configuration: IConfiguration = {
  minReplaceRegexp: /\{min}/g,
  maxReplaceRegexp: /\{max}/g,
}

const config = (configuration: IConfiguration) => {
  const { locale } = configuration
  if (locale) {
    setLocale(locale)
  }

  setConfiguration(configuration)
}

const getConfig = () => Configuration

const setConfiguration = (configuration: IConfiguration) => {
  Configuration = configuration
  return Configuration
}

const FormValidation = {
  config,
  getConfig,
  getLocale,
}

export default FormValidation
