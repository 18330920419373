import React from 'react'
import { UUID } from '@lib/types/general'
import classNames from 'classnames'

type SelectedValue = string | UUID | boolean

interface IButtonDescriptor {
  label: string
  value: SelectedValue
}

interface IButtonGroup {
  buttons: IButtonDescriptor[]
  selected: SelectedValue[]
  disabled?: boolean
  fontSizeClass?: string
  heightClass?: string
  containerClass?: string
  onChange: Function
}

const theme = {
  shared:
    'relative inline-flex flex-1 whitespace-nowrap justify-center items-center px-3 py-2 font-semibold',
  active: {
    true: 'bg-primary text-white-gray hover:bg-electric-violet-500 disabled:hover:bg-primary',
    false:
      'text-primary hover:bg-electric-violet-500 hover:text-white-gray disabled:hover:text-primary disabled:hover:bg-transparent',
  },
}

const ButtonGroup = ({
  buttons,
  selected,
  disabled,
  fontSizeClass = 'text-sm',
  heightClass = 'h-[44px]',
  containerClass,
  onChange,
}: IButtonGroup) => {
  return (
    <span
      className={classNames([
        'isolate inline-flex rounded-md shadow-sm border-2 border-primary',
        containerClass,
      ])}
    >
      {buttons.map(({ label, value }, index) => {
        const isActive = selected.includes(value)
        return (
          <button
            key={value.toString()}
            type="button"
            disabled={disabled}
            className={classNames([
              theme.shared,
              theme.active[isActive.toString()],
              fontSizeClass,
              heightClass,
              {
                'border-r-white':
                  index < buttons.length - 1 &&
                  isActive &&
                  selected.includes(buttons[index + 1]?.value),
                'border-r-2 border-primary': index !== buttons.length - 1,
              },
            ])}
            onClick={() => onChange({ value, isActive })}
          >
            {label}
          </button>
        )
      })}
    </span>
  )
}

export default ButtonGroup
