import React, { useMemo } from 'react'
import isEmpty from 'ramda/src/isEmpty'
import { partitionByIndex } from '@utils/common'
import Tooltip from '@components/Shared/Tooltip'
import Badge from '@components/Ui/Badge'
import classNames from 'classnames'
import type { IMultiselectProps } from '../Multiselect'

const theme = {
  buttonLabel: {
    shared: 'block truncate text-sm leading-none',
    error: 'text-watermelon-900',
    placeholder: 'text-oxford-gray-300',
    disabled: 'text-oxford-gray-400',
  },
}

const RestItemsCounter = ({
  restItems,
  renderTooltipItem,
  textField,
}: {
  textField: string
  restItems: any[]
  renderTooltipItem?: IMultiselectProps['renderTooltipItem']
}) => (
  <Tooltip
    interactive
    content={
      <div className="max-h-[400px] overflow-auto">
        {restItems.map((a, i) => (
          <div key={a.id || i}>
            {renderTooltipItem ? (
              renderTooltipItem({ item: a })
            ) : (
              <div key={a.id} className="px-2">
                {a[textField]}
              </div>
            )}
          </div>
        ))}
      </div>
    }
  >
    <Badge text={`+${restItems.length}`} hideRemoveBtn />
  </Tooltip>
)

const MultiselectButtonContent = ({
  value,
  error,
  disabled,
  placeholder,
  textField,
  valueField,
  open,
  onChange,
  renderTooltipItem,
  displayedItemsCount = 2,
  isItemRemovable,
}) => {
  const [visibleItems, restItems] = useMemo(
    () => partitionByIndex(value, displayedItemsCount),
    [value, displayedItemsCount],
  )

  const onRemoveItem = (e, item) => {
    e.stopPropagation()
    onChange(
      value.filter((v) => v[valueField] !== item[valueField]),
      {
        action: 'remove',
        dataItem: item,
      },
    )
  }

  return !isEmpty(visibleItems) ? (
    <div className="flex flex-wrap items-center">
      {visibleItems.map((item) => (
        <Badge
          key={item[valueField]}
          text={item[textField]}
          onRemove={(e) => onRemoveItem(e, item)}
          hideRemoveBtn={!isItemRemovable?.({ item })}
        />
      ))}
      {!isEmpty(restItems) && (
        <RestItemsCounter
          textField={textField}
          restItems={restItems}
          renderTooltipItem={renderTooltipItem}
        />
      )}
    </div>
  ) : (
    <span className="flex items-center mt-2 mb-3">
      <span
        className={classNames([
          theme.buttonLabel.shared,
          {
            [theme.buttonLabel.error]: !!error && !open,
            [theme.buttonLabel.disabled]: disabled,
            [theme.buttonLabel.placeholder]:
              !!placeholder && !error && !disabled,
          },
        ])}
      >
        {placeholder}
      </span>
    </span>
  )
}

export default MultiselectButtonContent
