export const REPORT_PATHS = {
  AGGREGATED: 'aggregated-report',
  ALL_TASKS: 'all-tasks',
  LATE_TASKS: 'late-tasks',
  ALL_MILESTONES: 'all-milestones',
  QUANTITY_AND_COST: 'quantity-and-cost',
  TIMELINE: 'timeline',
  TIME_TRACKING: 'time-tracking',
  USAGE: 'usage-report',
  KEY_MILESTONES: 'key-milestones',
}
