import { ListboxOption } from '@headlessui/react'
import classnames from 'classnames'
import type { IDropdown } from '../Dropdown'

interface IDropdownListOption extends Partial<IDropdown> {
  dataItem: any
}

const DropdownListOption = ({
  dataItem,
  textField = 'name',
  valueField = 'id',
  value,
  renderItem,
}: IDropdownListOption) => (
  <ListboxOption
    className={({ focus }) =>
      classnames([
        'cursor-pointer select-none relative border-t border-t-oxford-gray-100',
        {
          'text-primary bg-electric-violet-50': focus,
          'text-oxford-gray-800': !focus,
          'bg-electric-violet-100':
            dataItem[valueField] === value?.[valueField],
        },
      ])
    }
    value={dataItem}
  >
    {({ selected }) => (
      <>
        {typeof renderItem === 'function' ? (
          renderItem({ item: dataItem, inButton: false })
        ) : (
          <div className="flex items-center p-3">
            <span
              className={classnames([
                'block truncate text-xs',
                {
                  'font-semibold': selected,
                  'font-normal': !selected,
                },
              ])}
            >
              {dataItem[textField]}
            </span>
          </div>
        )}
      </>
    )}
  </ListboxOption>
)

export default DropdownListOption
