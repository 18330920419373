import { FormikErrors } from 'formik'
import React from 'react'

export interface IFormErrorProps {
  error: string | string[] | FormikErrors<any> | FormikErrors<any>[]
}

export function FormError({ error }: IFormErrorProps) {
  return (
    <div className="flex items-center mt-2">
      <p className="text-watermelon-800 text-2xs leading-none tracking-wide">
        <>{error}</>
      </p>
    </div>
  )
}

export function HelpText({ text }: { text: string }) {
  return (
    <div className="flex items-center mt-2">
      <p className="text-oxford-gray-800 text-2xs leading-none tracking-wide">
        <>{text}</>
      </p>
    </div>
  )
}
