export const MATERIALS_MANAGEMENT_PATHS = {
  MATERIALS_DATABASE: 'materials-database',
  BILL_OF_MATERIALS: 'bill-of-materials',
  ORDER_MATRIX: 'order-matrix',
  MATERIAL_ORDERS: 'orders',
  CREATE_ORDER: 'orders/create-order',
  EDIT_ORDER: 'orders/edit-order',
  ORDER_DETAILS: 'orders/[id]',
  STOCK_INVENTORY: 'stock-inventory',
  TRANSACTIONS: 'transactions',
  DOCUMENTS: 'documents',
  MATERIAL_REQUESTS: 'materials-requests',
  WAREHOUSES: 'warehouses',
  SUPPLIERS: 'suppliers',
  MANUFACTURERS: 'manufacturers',
  ADDRESSES: 'addresses',
  MATERIAL_TYPES: 'material-types',
}
