import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import { Url } from 'next/dist/shared/lib/router/router'

export const constructUrlWithOrgId = ({
  orgId,
  url,
}: {
  orgId: string
  url: Url
}) => {
  if (!url || typeof orgId !== 'string') return url

  const isExternalUrl =
    typeof url === 'string' &&
    ['http://', 'https://'].find((prefix) => (url as string).startsWith(prefix))

  if (isExternalUrl) return url

  if (typeof url === 'string') {
    const urlWithOrgId = `/${orgId}${url}`

    url = urlWithOrgId
  } else {
    if (url.pathname.includes('[providerOrganizationId]')) {
      url = {
        ...url,
        pathname: url.pathname,
        query: {
          ...(url.query as NextParsedUrlQuery),
          providerOrganizationId: orgId,
        },
      }
    } else {
      const urlWithOrgId = `/[providerOrganizationId]${url.pathname}`

      url = {
        ...url,
        pathname: urlWithOrgId,
        query: {
          ...(url.query as NextParsedUrlQuery),
          providerOrganizationId: orgId,
        },
      }
    }
  }

  return url
}
