export interface IDropdownClearButtonProps {
  onClick: () => void
}

export const DropdownClearButton = ({ onClick }: IDropdownClearButtonProps) => (
  <span
    className="icon-right absolute inset-y-0 right-0 flex items-center ml-3 pr-3 hover:cursor-pointer"
    onClick={(e) => {
      e.stopPropagation()
      onClick()
    }}
  >
    <i className="far fa-times" aria-hidden="true" />
  </span>
)
