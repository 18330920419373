import React, { ReactElement } from 'react'
import useSharedData from '@lib/hooks/useSharedData'
import { SessionExtended } from '@lib/types/nextauth'

interface IWithSharedData {
  session: SessionExtended
  children: React.ReactNode
}

const WithSharedData = ({ children }: IWithSharedData) => {
  useSharedData()

  return children
}

const withSession =
  (Component) =>
  //eslint-disable-next-line
  ({
    session,
    children,
    ...rest
  }: {
    children: ReactElement
    session: SessionExtended
  }) => {
    if (!session) return children
    return <Component {...rest}>{children}</Component>
  }

export default withSession(WithSharedData)
