import React from 'react'
import classnames from 'classnames'
import {  ListboxButton } from '@headlessui/react'
import type { IMultiselectProps } from '../Multiselect'
import MultiselectButtonContent from '../ButtonContent/MultiselectButtonContent'
import type { Placement } from '@popperjs/core'
import Spinner from '@components/Shared/Loaders/Spinner'
import useTranslations from '@locales/useTranslations'

interface IMultiselectButton extends IMultiselectProps {
  open: boolean
  popperPlacement: Placement | null
  setButtonElement: Function
}

const theme = {
  button: {
    shared:
      'relative w-100 rounded-md border pt-2 pl-2 pr-4 data-[headlessui-state=open]:border-electric-violet-500',
    default: 'border-oxford-gray-200',
    error: 'border-watermelon-800',
    disabled: 'bg-oxford-gray-50 border-[#E5E7EB]',
  },
}

const MultiselectButton = ({
  id = '',
  name = '',
  className,
  hideButton,
  value,
  placeholder,
  textField = 'name',
  valueField = 'id',
  title,
  open,
  disabled,
  setButtonElement,
  error,
  loading,
  displayedItemsCount,
  popperPlacement,
  renderTooltipItem,
  isItemRemovable,
  renderContent,
  onChange,
  onBlur,
}: IMultiselectButton) => {
  const { t } = useTranslations()

  if (!placeholder) {
    placeholder = t('expressions.select')
  }

  const contentProps = {
    value,
    error,
    disabled,
    textField,
    valueField,
    open,
    title,
    placeholder,
    displayedItemsCount,
    onChange,
    renderTooltipItem,
    isItemRemovable,
  }

  return (
    <ListboxButton
      ref={(newRef) => {
        if (newRef) {
          setButtonElement((ref) => {
            if (ref) {
              return ref
            }

            return newRef
          })
        }
      }}
      onBlur={onBlur}
      className={
        !hideButton
          ? classnames([
              theme.button.shared,
              {
                [theme.button.default]: !error && !disabled,
                [theme.button.error]: !!error && !disabled,
                [theme.button.disabled]: disabled,
                'data-[headlessui-state=open]:rounded-b-none':
                  popperPlacement && popperPlacement !== 'top',
                'data-[headlessui-state=open]:rounded-t-none':
                  popperPlacement && popperPlacement === 'top',
              },
              className,
            ])
          : ''
      }
      name={name}
      id={id}
    >
      {!hideButton && (
        <>
          <div className="flex flex-auto flex-wrap">
            {typeof renderContent === 'function' ? (
              renderContent(contentProps)
            ) : (
              <MultiselectButtonContent {...contentProps} />
            )}
          </div>

          <span className="absolute inset-y-0 right-0 px-3 flex items-center pointer-events-none">
            {loading ? (
              <Spinner />
            ) : (
              <i
                className="fas fa-caret-down text-oxford-gray-800 text-sm"
                aria-hidden="true"
              />
            )}
          </span>
        </>
      )}
    </ListboxButton>
  )
}

export default MultiselectButton
