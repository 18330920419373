import { is } from 'ramda'
import useUser from '@lib/hooks/useUser'
import { Role } from '@lib/types/organization'
import { User } from '@lib/types/user'
import useNextAuthSession from '@lib/hooks/useSession'

export type CustomAuthorizator = (args: {
  account: User
  userRoles: Role[]
  userPermissions: string[]
}) => boolean

interface IAuthorizatorProps {
  children: ({ authorized }: { authorized: boolean }) => any
  roles?: string[]
  permissions?: string[]
  custom?: CustomAuthorizator
}

const Authorizator = ({
  children,
  roles,
  permissions,
  custom,
}: IAuthorizatorProps) => {
  const { account, roles: userRoles, loading } = useUser()
  const { data: session, status } = useNextAuthSession()

  if (
    loading ||
    status === 'loading' ||
    status === 'unauthenticated' ||
    !account
  ) {
    return children({
      authorized: false,
    })
  }

  const userPermissions = session?.permissions ?? []

  if (!!roles && !roles.find((r) => userRoles.some((ur) => ur.name === r))) {
    return children({
      authorized: false,
    })
  }

  if (
    !!permissions &&
    !permissions.find((p: string) => userPermissions.some((up) => up === p))
  ) {
    return children({
      authorized: false,
    })
  }

  if (
    is(Function)(custom) &&
    !custom({ account, userRoles, userPermissions })
  ) {
    return children({
      authorized: false,
    })
  }

  return children({
    authorized: true,
  })
}

export default Authorizator
