import { useEffect } from 'react'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import { bg, enUS, fr } from 'date-fns/locale'
import { Language } from '@lib/graphql/__generated__/graphql'

const locales = {
  [Language.En]: enUS,
  [Language.Bg]: bg,
  [Language.Fr]: fr,
}

const useDateOptions = ({ lang }: { lang: string }) => {
  useEffect(() => {
    setDefaultOptions({
      weekStartsOn: 1,
      locale: lang ? locales[lang] || enUS : enUS,
    })
  }, [lang])
}

export default useDateOptions
