import { serialize as serializeGoalDetailsUpdate } from '@lib/hooks/useUpdateGoalDetails'
import { Goal } from '@lib/types/goals'
import { isServiceMilestone } from './milestones'
import { ENTITY_STATUS } from './constants'
import { getDateFromTimeStamp, isValidDate } from './Date'
import { REMINDER_TYPE_FEATURES } from './Dashboard/dashboardReminderUtil'
import { DynamicPlanning } from '@lib/graphql/__generated__/graphql'

export const serializeMilestoneUpdate = ({
  label,
  type,
  tasks,
  task,
  milestoneDependencyIds,
  allowedStatuses,
  status,
  position,
  delay,
  project,
  milestone,
  goal,
  productionPlanningProject,
  keyMilestonesProject,
  tasksMeta,
  projectMaterial,
  order,
  thread,
  ...rest
}) => {
  return {
    ...rest,
    id: milestone?.id,
    labelId: label?.id,
  }
}

export const serializeGoalUpdate = ({
  milestone,
  goal,
  tasks,
  task,
  type,
  project,
  dynamicPlanner,
  tasksMeta,
  projectMaterial,
  order,
  sections,
  ...rest
}) => ({
  ...serializeGoalDetailsUpdate(rest as Goal),
  remindMe: dynamicPlanner,
})

export const isDynamicPlannerEditable = (params) => {
  const { data } = params
  const task = data?.task

  return (
    !isServiceMilestone(params) &&
    data?.status !== ENTITY_STATUS.CANCELED &&
    (isValidDate(task?.deadline)
      ? getDateFromTimeStamp(task.deadline) > new Date()
      : true)
  )
}

export const shouldRenderFeatureReminder = ({ reminderType, features }) => {
  const feature = REMINDER_TYPE_FEATURES[reminderType]

  return feature ? features.includes(feature) : true
}

export const getDynamicPlanningRowId = (data: DynamicPlanning) => {
  return (
    data?.milestone?.id ||
    data?.goal?.id ||
    data?.task?.id ||
    data?.projectMaterial?.id ||
    data?.project?.id ||
    data?.order?.id ||
    data?.thread?.id ||
    data?.productionPlanningProject?.id ||
    data?.keyMilestonesProject?.id
  )
}
