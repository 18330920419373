import dynamic from 'next/dynamic'

const dynamicNamedImport: (ComponentName: string) => any = (ComponentName) =>
  dynamic(() =>
    import('./DataLoaders').then((module) => module.default[ComponentName]),
  )

export const WithTeams = dynamicNamedImport('WithTeams')

export const WithClients = dynamicNamedImport('WithClients')

export const WithJobTitles = dynamicNamedImport('WithJobTitles')

export const WithProducers = dynamicNamedImport('WithProducers')

export const WithUsers = dynamicNamedImport('WithUsers')

export const WithUsersWithTeams = dynamicNamedImport('WithUsersWithTeams')

export const WithUserAccount = dynamicNamedImport('WithUserAccount')

export const WithProjectTypes = dynamicNamedImport('WithProjectTypes')

export const WithProjects = dynamicNamedImport('WithProjects')

export const WithTimeFactors = dynamicNamedImport('WithTimeFactors')

export const WithSizeStandards = dynamicNamedImport('WithSizeStandards')

export const WithProjectDetails = dynamicNamedImport('WithProjectDetails')

export const WithBaseMilestones = dynamicNamedImport('WithBaseMilestones')

export const WithManufacturers = dynamicNamedImport('WithManufacturers')

export const WithSuppliers = dynamicNamedImport('WithSuppliers')

export const WithAddresses = dynamicNamedImport('WithAddresses')

export const WithMaterialTypes = dynamicNamedImport('WithMaterialTypes')

export const WithProcedures = dynamicNamedImport('WithProcedures')

export const WithLabels = dynamicNamedImport('WithLabels')

export const WithDocumentCategories = dynamicNamedImport(
  'WithDocumentCategories',
)

export const WithCategoriesForContextType = dynamicNamedImport(
  'WithCategoriesForContextType',
)

export const WithProjectMilestoneDetails = dynamicNamedImport(
  'WithProjectMilestoneDetails',
)

export const WithTaskRelatedTasks = dynamicNamedImport('WithTaskRelatedTasks')

export const WithRoles = dynamicNamedImport('WithRoles')

export const WithResources = dynamicNamedImport('WithResources')

export const WithRecurringDefinition = dynamicNamedImport(
  'WithRecurringDefinition',
)

export const WithCounters = dynamicNamedImport('WithCounters')

export const WithAggregatedReport = dynamicNamedImport('WithAggregatedReport')

export const WithQuantityCostReport = dynamicNamedImport(
  'WithQuantityCostReport',
)

export const WithAggregatedReportDetails = dynamicNamedImport(
  'WithAggregatedReportDetails',
)

export const WithBoards = dynamicNamedImport('WithBoards')

export const WithCollections = dynamicNamedImport('WithCollections')
