import { SessionExtended } from '@lib/types/nextauth'
import {
  SessionContextValue,
  useSession,
  UseSessionOptions,
} from 'next-auth/react'

type NextAuthSessionContextValue = {
  data: SessionExtended
  status: SessionContextValue['status']
  update: (data?: any) => Promise<SessionExtended | null>
}

const useNextAuthSession = <R extends boolean>(
  options?: UseSessionOptions<R>
) => {
  const { data, status, update } = useSession(options)

  return { data, status, update } as NextAuthSessionContextValue
}

export default useNextAuthSession
