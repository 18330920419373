import { useCallback, useContext, useEffect } from 'react'
import { TranslationContext } from '@locales/TranslationsProvider/TranslationsProvider'
import path from 'ramda/src/path'

export type TFunction = (key: string, keywords?: string[]) => string

export type UseTranslations = () => {
  t: TFunction
  lang: string
}

const checkContext = (translationContext) => {
  if (!translationContext?.lang) {
    throw new Error(
      "Translations Context not found. You can't use this hook outside 'TranslationsProvider'.",
    )
  }

  if (!translationContext?.translations) {
    throw new Error(
      `Translations missing for this language '${translationContext.lang}'`,
    )
  }
}

const useTranslations: UseTranslations = () => {
  const translationsContext = useContext(TranslationContext)

  useEffect(() => {
    checkContext(translationsContext)
  }, [translationsContext])

  const translate: TFunction = useCallback(
    (key, keywords) => {
      const { translations } = translationsContext
      const value: string | undefined = path(key.split('.'))(translations)

      if (!value) return key

      if (Array.isArray(keywords)) {
        const mutableKeywords = [...keywords]
        const replacedValue = value.replaceAll(/{.*?}/g, () =>
          mutableKeywords.shift(),
        )

        return replacedValue
      }

      return value
    },
    [translationsContext],
  )

  return { t: translate, lang: translationsContext?.lang }
}

export default useTranslations
