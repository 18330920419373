import React, { forwardRef } from 'react'
import NextLink, { LinkProps } from 'next/link'
import useNextAuthSession from '@lib/hooks/useSession'
import { constructUrlWithOrgId } from './utils'

type ILink = React.FunctionComponent<
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
      children?: React.ReactNode
    } & React.RefAttributes<HTMLAnchorElement>
>

/* eslint-disable react/display-name */
const Link: ILink = forwardRef(({ children, href, ...rest }, ref) => {
  const { data: session } = useNextAuthSession()
  const orgId = session?.orgId

  const hrefWithOrgId = constructUrlWithOrgId({ orgId, url: href })

  return (
    <NextLink {...rest} ref={ref} href={hrefWithOrgId}>
      {children}
    </NextLink>
  )
})

export default Link
