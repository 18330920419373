import React from 'react'
import classnames from 'classnames'

interface IBadgeProps {
  text: string
  className?: string
  hideRemoveBtn?: boolean
  onRemove?: Function
}

const Badge = ({ text, className, hideRemoveBtn, onRemove }: IBadgeProps) => {
  return (
    <div
      className={classnames([
        'flex justify-center items-center rounded-md bg-oxford-gray-100 border border-[#B0BAC9] py-[7px] px-2 mr-2 mb-2 max-w-[180px]',
        className,
      ])}
    >
      <div className={'text-sm text-oxford-gray-800 leading-none truncate'}>
        {text}
      </div>
      {!hideRemoveBtn && (
        <a className="flex items-center pl-2" onClick={(e) => onRemove(e)}>
          <i className="far fa-times text-sm leading-none"></i>
        </a>
      )}
    </div>
  )
}

export default Badge
