import React from 'react'
import { User } from '@lib/types/user'
import UserAvatar from '../UserAvatar'
import Tooltip from '../Tooltip/Tooltip'
import path from 'ramda/src/path'
import classNames from 'classnames'
import { Placement, right } from '@popperjs/core'
import { Account } from '@lib/graphql/__generated__/graphql'

interface IUserRendererProps {
  value: User | Account
  avatarSize?: number
  withName?: boolean
  nameClass?: string
  withJobTitle?: boolean
  className?: string
  tooltipPlacement?: Placement
  tooltipDelayShow?: number
}

export const getFullName = (user: User) => {
  if (!user) return 'User'

  return user.fullName
}

export const AvatarPopupContent = ({ user }: { user: User }) => (
  <div className="flex items-center">
    <div className="mr-2">
      <UserAvatar size={56} name={user.fullName} accountId={user.id} />
    </div>
    <div className="flex flex-col text-oxford-gray-800">
      <span className="font-bold">{user.fullName}</span>
      <span className="text-oxford-gray-400">
        {path(['jobTitle', 'name'])(user)}
      </span>
    </div>
  </div>
)

const UserRenderer = (props: IUserRendererProps) => {
  const {
    value,
    avatarSize,
    withName = true,
    nameClass,
    withJobTitle = true,
    className,
    tooltipPlacement = right,
    tooltipDelayShow = 0,
  } = props
  const fullName = getFullName(value as User)

  if (!value) return null

  const userRender = (
    <UserAvatar name={fullName} size={avatarSize} accountId={value?.id} />
  )

  if (!withName) {
    return (
      <Tooltip
        placement={tooltipPlacement}
        delayShow={tooltipDelayShow}
        content={<AvatarPopupContent user={value as User} />}
      >
        {userRender}
      </Tooltip>
    )
  }

  return (
    <div className={classNames(['flex items-center', className])}>
      {userRender}

      {withName && (
        <div className="flex flex-col items-start mx-2 min-w-0">
          <span
            className={classNames([
              'leading-tight text-sm truncate w-100',
              nameClass,
            ])}
          >
            {fullName}
          </span>
          {withJobTitle && value.jobTitle && (
            <span className="leading-tight text-2xs text-oxford-gray-400 truncate w-100">
              {path(['jobTitle', 'name'])(value)}
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default UserRenderer
