import { makeVar } from '@apollo/client'

export const webSocketReconnectedVar = makeVar(false)
let initialConnection = true
let reconnectionTimeout = null

export const triggerReconnection = () => {
  if (initialConnection) {
    initialConnection = false
    return
  }

  if (reconnectionTimeout) clearTimeout(reconnectionTimeout)

  webSocketReconnectedVar(true)
  reconnectionTimeout = setTimeout(() => webSocketReconnectedVar(false), 100)
}
