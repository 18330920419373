import { EntityStatus, RecurringDefinitionStatus } from '@lib/types/general'
import { TFunction } from '@locales/useTranslations'

export const ENTITY_STATUS: Record<string, EntityStatus> = {
  NOT_STARTED: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  CANCELED: 'CANCELED',
}

export const RECURRING_DEFINITION_STATUS: Record<
  string,
  RecurringDefinitionStatus
> = {
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  FINISHED: 'FINISHED',
}

export const getEntityStatusMeta = (t?: TFunction) => {
  const entityStatusMeta = {
    [ENTITY_STATUS.NOT_STARTED]: {
      key: ENTITY_STATUS.NOT_STARTED,
      value: t?.('status.new'),
      icon: 'text-lg far fa-sticky-note text-oxford-gray-400',
    },
    [ENTITY_STATUS.IN_PROGRESS]: {
      key: ENTITY_STATUS.IN_PROGRESS,
      value: t?.('status.inProgress'),
      icon: 'text-lg far fa-redo-alt fa-flip-horizontal fa-flip-vertical text-cornflower-blue-400',
    },
    [ENTITY_STATUS.DONE]: {
      key: ENTITY_STATUS.DONE,
      value: t?.('status.done'),
      icon: 'text-lg far fa-check text-jade-500',
    },
    [ENTITY_STATUS.CANCELED]: {
      key: ENTITY_STATUS.CANCELED,
      value: t?.('status.canceled'),
      icon: 'text-lg far fa-ban text-watermelon-800',
    },
  }

  return entityStatusMeta
}

export const getRecurringDefinitionStatusMeta = (t?: TFunction) => {
  const recurringDefinitionStatusMeta = {
    [RECURRING_DEFINITION_STATUS.IN_PROGRESS]: {
      key: RECURRING_DEFINITION_STATUS.IN_PROGRESS,
      value: t?.('status.inProgress'),
      icon: 'text-lg far fa-redo-alt fa-flip-horizontal fa-flip-vertical text-cornflower-blue-400',
    },
    [RECURRING_DEFINITION_STATUS.PAUSED]: {
      key: RECURRING_DEFINITION_STATUS.PAUSED,
      value: t?.('status.paused'),
      icon: 'text-lg far fa-pause text-cornflower-blue-400',
    },
    [RECURRING_DEFINITION_STATUS.FINISHED]: {
      key: RECURRING_DEFINITION_STATUS.FINISHED,
      value: t?.('status.finished'),
      icon: 'text-lg far fa-check text-jade-500',
    },
  }

  return recurringDefinitionStatusMeta
}
