import React from 'react'
import type { IMultiselectProps } from '../Multiselect'
import { ListboxOption } from '@headlessui/react'
import classnames from 'classnames'

interface IOptionProps extends Partial<IMultiselectProps> {
  dataItem: any
  onClick: React.MouseEventHandler<HTMLElement>
}

const MultiselectOption = ({
  textField,
  valueField,
  value,
  dataItem,
  renderItem,
  disabled,
  onClick,
}: IOptionProps) => {
  const selected = value.find((v) => v[valueField] === dataItem[valueField])
  const renderItemResult = renderItem?.({ item: dataItem })

  return (
    <ListboxOption
      key={dataItem.id}
      disabled={disabled}
      onClick={onClick}
      className={({ focus }) =>
        classnames([
          'select-none relative border-t border-t-oxford-gray-100',
          {
            'text-primary bg-electric-violet-50 cursor-pointer':
              focus && !disabled,
            'text-oxford-gray-800': !focus && !disabled,
            'bg-electric-violet-100': selected && !disabled,
            'bg-oxford-gray-100 text-oxford-gray-300': disabled,
          },
        ])
      }
      value={dataItem}
    >
      {() => (
        <>
          {renderItemResult ? (
            renderItemResult
          ) : (
            <div className="flex items-center p-3">
              <span
                className={classnames([
                  'block truncate text-xs',
                  {
                    'font-semibold': selected,
                    'font-normal': !selected,
                  },
                ])}
              >
                {dataItem[textField]}
              </span>
            </div>
          )}
        </>
      )}
    </ListboxOption>
  )
}

export default MultiselectOption
