import { useFormikContext } from 'formik'
import UserContributor from '../AccessComponents/UserContributor'
import useTranslations from '@locales/useTranslations'
import { CONTRIBUTOR_RIGHTS } from '@utils/constants'
import { User } from '@lib/types/user'
import TeamContributorCard from '../AccessComponents/TeamContributorCard'
import Panel from '@components/Ui/Panel/Panel'
import ContributorContainer from '../AccessComponents/ContributorContainer'

export interface IDefaultTab {
  onOwnerChange: Function
  resourceOwner: User
}

const DefaultTab = ({ resourceOwner }: IDefaultTab) => {
  const { values }: any = useFormikContext()

  const { t } = useTranslations()

  const { OWNER } = CONTRIBUTOR_RIGHTS(t)

  const projectResponsible = values?.userContributors?.find(
    (account) => account.id === resourceOwner?.id,
  )

  const restUserContributors = values?.userContributors?.filter(
    (account) => account.id !== resourceOwner?.id,
  )

  return (
    <>
      {resourceOwner && (
        <ContributorContainer
          title={t('access.projectOwner')}
          containerClassName="bg-oxford-gray-50"
          contributors={
            <UserContributor
              key={resourceOwner?.id}
              account={resourceOwner}
              access={OWNER.value}
              showMoreActions={false}
            />
          }
        />
      )}

      {projectResponsible && (
        <UserContributor
          key={projectResponsible?.id}
          account={projectResponsible.account}
          disabled
          access={projectResponsible.access}
          showMoreActions={false}
          isProjectResponsible
        />
      )}

      {!!values?.teamContributors?.length && (
        <ContributorContainer
          title={t('access.teams')}
          contributors={values.teamContributors.map((teamContributor) => (
            <TeamContributorCard
              disabled
              key={teamContributor.team.id}
              teamContributor={teamContributor}
              projectResponsibleId={projectResponsible?.account?.id}
              resourceOwnerId={resourceOwner?.id}
            />
          ))}
        />
      )}

      {restUserContributors?.length > 0 && (
        <ContributorContainer
          title={t('access.individualUsers')}
          contributors={
            <Panel
              title={t('access.users')}
              buttonAs="div"
              shadowClassName=""
              labelClassName="text-sm"
              className="mb-3"
              btnClassName="bg-electric-violet-50"
            >
              <div className="pl-3">
                <div>
                  {values.userContributors.map(
                    ({ account, access, ...rest }) => (
                      <UserContributor
                        key={account.id}
                        account={account}
                        access={access}
                        disabled
                        {...rest}
                      />
                    ),
                  )}
                </div>
              </div>
            </Panel>
          }
        />
      )}
    </>
  )
}

export default DefaultTab
