import React, { useEffect, useMemo } from 'react'
import FormValidation from '@utils/formValidation/index'
import formLocalesEN from '@locales/en/form-locales.json'
import formLocalesBG from '@locales/bg/form-locales.json'
import formLocalesFR from '@locales/fr/form-locales.json'
import { locales } from '@utils/i18n'

export const formLocales = {
  en: formLocalesEN,
  bg: formLocalesBG,
  fr: formLocalesFR,
}

export interface ITranslationsProviderProps {
  lang: string
  children: any
}

export const TranslationContext = React.createContext(null)

const TranslationsProvider = ({
  lang,
  children,
}: ITranslationsProviderProps) => {
  const translations = locales[lang]

  const contextValue = useMemo(
    () => ({ lang, translations }),
    [lang, translations],
  )

  useEffect(() => {
    // feed FormValidation with the latest form translations
    FormValidation.config({
      minReplaceRegexp: /\{min}/g,
      maxReplaceRegexp: /\{max}/g,
      locale: formLocales[lang],
    })
  }, [lang])

  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  )
}

export default TranslationsProvider
