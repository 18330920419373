import { gql } from '../__generated__'

export const SAVE_GOAL = gql(`
  mutation SaveGoal($input: SaveGoalInput!) {
    saveGoal(input: $input) {
      ...MyGoalFragment
    }
  }
`)

export const DELETE_GOAL = gql(`
  mutation DeleteGoal($input: GetGoalInput!) {
    deleteGoal(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const SAVE_BOARD_GOAL = gql(`
  mutation SaveBoardGoal($input: SaveGoalInput!) {
    saveGoal(input: $input) {
      ...BoardGoalFragment
    }
  }
`)

export const UPDATE_BOARD_GOAL = gql(`
  mutation UpdateBoardGoal($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      ...BoardGoalFragment
    }
  }
`)

export const ADD_GOAL_TO_SECTION = gql(`
  mutation AddGoalToSection($input: GoalSectionInput!) {
    addGoalToSection(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const REMOVE_GOAL_FROM_SECTION = gql(`
  mutation RemoveGoalFromSection($input: GoalSectionInput!) {
    removeGoalFromSection(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const MOVE_GOAL_TO_SECTION = gql(`
  mutation MoveGoalToSection($input: MoveGoalToSectionInput!) {
    moveGoalToSection(input: $input) {
      ...EmptyResponseFragment
    }
  }
`)

export const UPDATE_GOAL_DETAILS = gql(`
  mutation UpdateGoalDetails($input: UpdateGoalInput!) {
    updateGoal(input: $input) {
      ...GoalDetailsFragment
    }
  }
`)

export const FOLLOW_UNFOLLOW_GOAL = gql(`
  mutation GoalFollowerAction($input: GoalFollowerActionInput!) {
    goalFollowerAction(input: $input) {
      ...GoalDetailsFragment   
    }
  }
`)
