import { onError } from '@apollo/client/link/error'
import { showFailureToast } from '@lib/graphql/variables/toastState'
import { getMainDefinition } from '@apollo/client/utilities'
import { GRAPHQL_EXTENSIONS_CODE, HTTP_STATUS } from '@utils/constants'
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql'
import { signOutClient } from '@utils/auth'

const isSubscriptionOperation = (
  definition: OperationDefinitionNode | FragmentDefinitionNode,
) =>
  definition.kind === 'OperationDefinition' &&
  definition.operation === 'subscription'

const isQueryOperation = (
  definition: OperationDefinitionNode | FragmentDefinitionNode,
) =>
  definition.kind === 'OperationDefinition' && definition.operation === 'query'

const getDisplayedMessageForCode = (code) => {
  if (!code) return null

  //TODO: translate
  const messages = {
    [GRAPHQL_EXTENSIONS_CODE.NOT_FOUND]: 'Entity not found',
    [GRAPHQL_EXTENSIONS_CODE.CONFLICT]: 'Conflict on update',
    [GRAPHQL_EXTENSIONS_CODE.FORBIDDEN]: 'Operation forbidden',
    [GRAPHQL_EXTENSIONS_CODE.INTERNAL_ERROR]: 'Internal error',
  }

  return messages[code] ?? null
}

const displayError = ({
  error,
  consoleError,
}: {
  error: string
  consoleError?: string
}) => {
  console.log(consoleError || error)
  showFailureToast(error)
}

const networkErrorHandler = ({ networkError }) => {
  //logout when server respond with unauthenticated
  //@ts-ignore
  if (networkError?.statusCode === HTTP_STATUS.UNAUTHORIZED) {
    signOutClient({
      callbackUrl:
        typeof window !== 'undefined'
          ? window.location.origin + '/auth/signed-out'
          : undefined,
    })
  } else {
    const fullError = `[Network error]: ${networkError}`

    displayError({ error: fullError })
  }
}

const graphQLErrorHandler = ({ graphQLErrors, definition }) => {
  graphQLErrors.map(({ message, locations, path, extensions }) => {
    if (extensions.classification === 'NullValueInNonNullableField') {
      // ignore the following error
      // 'The field at path '/saveResources' was declared as a non null type, but the code involved in retrieving data has wrongly returned a null value.'
      return
    }
    if (
      isQueryOperation(definition) &&
      [
        GRAPHQL_EXTENSIONS_CODE.FORBIDDEN,
        GRAPHQL_EXTENSIONS_CODE.NOT_FOUND,
      ].includes(extensions?.code as any)
    ) {
      // ignore these errors on queries
      return
    }

    const displayedMessage =
      getDisplayedMessageForCode(extensions?.code) || message
    const fullError = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`

    showFailureToast(displayedMessage || message)

    displayError({ error: displayedMessage, consoleError: fullError })
  })
}

const subscriptionErrorHandler = () => {
  // ignore subscription related errors
  return
}

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    const definition = getMainDefinition(operation.query)

    if (isSubscriptionOperation(definition)) {
      return subscriptionErrorHandler()
    }

    if (networkError) {
      return networkErrorHandler({ networkError })
    }

    if (graphQLErrors) {
      return graphQLErrorHandler({ graphQLErrors, definition })
    }
  },
)
