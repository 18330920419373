import React, { ElementType } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import classNames from 'classnames'

interface IPanelProps {
  title: string
  buttonAs?: ElementType
  defaultOpen?: boolean
  disabled?: boolean
  children?: React.ReactNode
  renderRight?: Function
  className?: string
  btnClassName?: string
  shadowClassName?: string
  labelClassName?: string
}

const Panel: React.FunctionComponent<IPanelProps> = ({
  title,
  defaultOpen,
  disabled,
  renderRight,
  children,
  className,
  btnClassName,
  buttonAs = 'button',
  shadowClassName = 'shadow-md',
  labelClassName,
}) => {
  const ButtonComponent = !disabled ? Disclosure.Button : 'div'

  return (
    <div
      className={classNames([
        'rounded-lg bg-white-gray w-100 border border-oxford-gray-200',
        shadowClassName,
        className,
      ])}
    >
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <ButtonComponent
              as={buttonAs}
              className={classNames([
                'w-100 p-3 flex items-center justify-between border-b border-b-oxford-gray-200',
                btnClassName,
                {
                  'rounded-t-lg': open,
                  'rounded-lg': !open,
                },
              ])}
            >
              <span
                className={classNames([
                  'text-oxford-gray-800 font-bold',
                  labelClassName,
                ])}
              >
                {title}
              </span>
              <div className="flex align-middle">
                {!disabled && !renderRight && (
                  <i
                    className={classNames([
                      'far fa-chevron-down text-oxford-gray-400 transform transition-transform text-lg',
                      {
                        'rotate-180': open,
                      },
                    ])}
                  />
                )}

                {renderRight && renderRight(open)}
              </div>
            </ButtonComponent>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="px-3 text-oxford-gray-800">
                {children}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}

export default Panel
