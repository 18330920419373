import React from 'react'
import Multiselect from '@components/Ui/Multiselect'
import identity from 'ramda/src/identity'
import path from 'ramda/src/path'
import type { IMultiselectProps } from '@components/Ui/Multiselect/Multiselect'
import type { UseSaveMutationOptions } from '@lib/hooks/useSaveMutation'
import useSaveMutation from '@lib/hooks/useSaveMutation'

export interface IDefaultProps extends Partial<IMultiselectProps> {
  allowCreate?: 'onFilter' | false
  createOptions?: UseSaveMutationOptions & { operationName: string }
  serializeCreate?: (item: any) => any
  queryVariables?: Record<any, any>
  renderItem?: IMultiselectProps['renderItem']
}

const makeEntityMultiselect = (
  DataLoader: React.FunctionComponent<any>,
  defaultProps: IDefaultProps,
) => {
  const EntityMultiselect = (
    props: JSX.IntrinsicAttributes &
      IMultiselectProps & { children?: React.ReactNode },
  ) => {
    const {
      createOptions,
      serializeCreate = identity,
      allowCreate,
      renderItem,
      renderTooltipItem,
      queryVariables,
      ...restDefaultProps
    } = defaultProps

    const [save] = useSaveMutation(createOptions)

    const onCreate = async (item) => {
      const response = await save({
        variables: {
          input: serializeCreate(item),
        },
      })

      const created = path(['data', createOptions.operationName])(response)

      return created
    }

    return (
      <Multiselect
        allowCreate={allowCreate}
        renderItem={renderItem}
        renderTooltipItem={renderTooltipItem}
        queryVariables={queryVariables}
        DataLoader={DataLoader}
        onCreate={onCreate}
        {...restDefaultProps}
        {...props}
      />
    )
  }

  return EntityMultiselect
}

export default makeEntityMultiselect
