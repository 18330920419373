import { ReactNode } from 'react'
import ReactDOM from 'react-dom'

export const PORTAL_ROOT_ID = 'hl-portal-root'

const isSS = typeof window === 'undefined'

const createPortal = (node: ReactNode) => {
  if (isSS) return null

  let element = document.getElementById(PORTAL_ROOT_ID)

  if (!element) {
    element = document.createElement('div')

    element.setAttribute('id', PORTAL_ROOT_ID)

    document.body.append(element)
  }

  return ReactDOM.createPortal(node, element)
}

export default createPortal
