import Dropdown, { IDropdown } from '@components/Ui/Dropdown/Dropdown'
import useTranslations from '@locales/useTranslations'
import { CONTRIBUTOR_RIGHTS } from '@utils/constants'
import values from 'ramda/src/values'

const UserAccessDropdown = (props: IDropdown) => {
  const { t } = useTranslations()

  const data = values(CONTRIBUTOR_RIGHTS(t))

  return (
    <Dropdown textField="label" valueField="value" data={data} {...props} />
  )
}

export default UserAccessDropdown
