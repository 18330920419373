import { lensPath, view, set } from 'ramda'
import { SAVE_CLIENT } from '@lib/graphql/mutations/Organization'
import { SEARCH_ACCOUNT } from '@lib/graphql/queries/Organization'
import { WithUsers } from '../Data'
import makeEntityDropdown from '../entityControl/makeEntityDropdown'
import UserRenderer from '../Renderer/UserRenderer'
import classNames from 'classnames'

const renderItem = ({ item, inButton }) => (
  <div
    className={classNames({
      'py-2 pl-2 pr-5': inButton,
      'p-2': !inButton,
    })}
  >
    <UserRenderer value={item} avatarSize={32} withJobTitle={!inButton} />
  </div>
)

const UserDropdown = makeEntityDropdown(WithUsers, {
  allowCreate: false,
  textField: 'fullName',
  renderItem,
  createOptions: {
    operationName: 'saveCategory',
    mutation: SAVE_CLIENT,
    updateQuery: SEARCH_ACCOUNT,
    updateResolver: (queryData, result) => {
      const lens = lensPath(['searchAccount', 'accounts'])
      const collection = view(lens, queryData)
      return set(lens, [result.data?.saveAccount, ...collection])(queryData)
    },
  },
  serializeCreate: (data) => ({
    ...data,
    isActive: true,
  }),
})

export default UserDropdown
