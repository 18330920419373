import React, { useEffect, useRef } from 'react'

interface ISearchBoxProps {
  value?: string
  onChange?: (filter: string) => void
  paddingClass?: string
}

const SearchBox = ({
  value,
  onChange,
  paddingClass = 'px-1 mb-1',
}: ISearchBoxProps) => {
  const ref = useRef<HTMLInputElement>()

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        ref.current?.focus?.()
      }, 0)
    }
  }, [])

  return (
    <div className={paddingClass}>
      <div className="relative text-oxford-gray-800">
        <input
          ref={ref}
          type="text"
          className="w-100 pl-2 pr-4 rounded-md h-5 border border-oxford-gray-200 text-xs focus:border-electric-violet-500"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === ' ') {
              // HeadlessUI Listbox is using the space to close the dropdown
              // prevent this here
              e.stopPropagation()
            }
          }}
        />
        <div className="absolute inset-y-0 right-0 px-2 flex items-center content-center">
          <i className="far fa-search text-sm"></i>
        </div>
      </div>
    </div>
  )
}

export default SearchBox
