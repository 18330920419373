import { gql } from '../__generated__'

export const SEARCH_THREADS = gql(`
  query SearchThreads($input: SearchThreadsInput!) {
    searchThreads(input: $input) {
      threads {
        ...ThreadInfoFragment
      }
      meta {
        ...PaginationMetadataFragment
      }
    }
  }
`)

export const SEARCH_RECENT_ACTIVITY_THREADS = gql(`
  query SearchRecentActivityThreads($pageRequest: PageRequestInput!, $unreadOnly: Boolean!) {
    recentThreads(pageRequest: $pageRequest, unreadOnly: $unreadOnly) {
      activities {
        ... on ThreadCommentActivity {
            thread {
                ...ThreadInfoFragment
            }
            recentParticipants {
                ...AccountFragment
            }
            lastParticipant {
                ...AccountFragment
            }
        }
        ... on ThreadLikeActivity {
            accounts {
              ...AccountFragment
            }
            comment {
              ...CommentFragment
              thread {
                id 
                title
              }
            }
          }
        }
      meta {
        ...PaginationMetadataFragment  
      }
    }
  }
`)

export const GET_THREAD = gql(`
  query GetThread($threadId: UUID!) {
    getThread(id: $threadId) {
      ...ThreadFragment
    }
  }
`)

export const GET_THREAD_COMMENTS = gql(`
  query GetThreadComments($threadId: UUID!) {
    getThread(id: $threadId) {
      ...ThreadCommentsFragment
    }
  }
`)
