import { CONTRIBUTOR_RIGHTS } from './constants'

export const teamToContributor = (team, resourceOwner) => {
  const viewPremission = CONTRIBUTOR_RIGHTS().VIEWER.value
  const editPermission = CONTRIBUTOR_RIGHTS().EDITOR.value

  const { members, teamLead } = team

  const teamLeader = {
    account: teamLead,
    access:
      teamLead?.id === resourceOwner?.id ? editPermission : viewPremission,
  }

  const teamMembers = members
    .map(({ account }) => ({
      account,
      access:
        account?.id === resourceOwner?.id ? editPermission : viewPremission,
    }))
    .filter((u) => u.account.id !== teamLeader.account.id)

  return { team, contributors: [teamLeader, ...teamMembers] }
}

export const getContributorsList = (contributors) =>
  contributors.map(({ account, access }) => ({
    access,
    accountId: account.id,
  }))
