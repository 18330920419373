import React from 'react'
import { ListboxOption } from '@headlessui/react'
import classNames from 'classnames'
import { initialFilterState } from './DropdownList'
import useTranslations from '@locales/useTranslations'

interface IDropdownListCreateOptionProps {
  filter: string
  textField: string
  setFilter: Function
}

const DropdownListCreateOption: React.FunctionComponent<
  IDropdownListCreateOptionProps
> = ({ textField, filter, setFilter }) => {
  const { t } = useTranslations()

  return (
    <ListboxOption
      as="button"
      className={({ focus }) =>
        classNames([
          'cursor-pointer relative p-3 w-100 border-t border-t-oxford-gray-200',
          {
            'text-primary bg-electric-violet-50': focus,
            'text-oxford-gray-800': !focus,
          },
        ])
      }
      value={{
        create: true,
        [textField]: filter.trim(),
      }}
      onClick={() => {
        setFilter(initialFilterState)
      }}
    >
      {() => (
        <div className="block truncate text-xs text-primary text-left">
          {t('buttons.createOption')}{' '}
          <span className="font-semibold">{filter}</span>?
        </div>
      )}
    </ListboxOption>
  )
}

export default DropdownListCreateOption
